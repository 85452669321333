import { LoadEnv } from "../utils/EnvUtils"
import { LocaleEnglish } from "./languages/English"
import { LocaleHindi } from "./languages/Hindi"

export type LocaleType = {
    id : LocaleID,
    name : string,
    nativeName : string,
    content : LocaleContentType
}

export type LocaleID = 
    | "en-us"
    | "hi-in"


export const loc = () => {
    const envLocale = LoadEnv().locale;
    return Locales.filter(l=>l.id === envLocale)[0].content;
}

export const Locales : LocaleType[] = [
    {
        id : "hi-in",
        name : "Hindi",
        nativeName : "हिन्दी",
        content : LocaleHindi
    },
    {
        id : "en-us",
        name : "English",
        nativeName : "English",
        content : LocaleEnglish
    }
]

export type LocaleContentType = {
    header : {
        search : {
            placeholder : string
        },
        userMenu : {
            myAccount : string,
            myShelves : string,
            settings : string,
            logout : string
        }
    },
    settings : {
        tabs : {
            general : string,
            generalContent : {
                displayLanguage : string,
                storyLanguage : string,
                storyLanguageSub : string,
                explicitContent : string,
                explicitContentSub : string
            }
            themes : string,
            themeContent : {
                theme : string,
                lowhardware : string,
                autoMood : string,
                autoMoodSub : string,
            }
        }
    }
}