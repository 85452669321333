import { FontPreferenceType } from "../types/EnvTypes";

export type FontType = {
    fontCSS : string,
    fontName : string 
}

export const Fonts : FontType[] = [
    {
        fontCSS : "'Merriweather', serif",
        fontName : "Merriweather"
    },
    {
        fontCSS : "'Open Sans', sans-serif",
        fontName : "Open Sans"
    },
    {
        fontCSS : "'Indie Flower', cursive",
        fontName : "Indie Flower"
    },
    {
        fontCSS : "'Inconsolata', monospace",
        fontName : "Inconsolata"
    },
    {
        fontCSS : "manrope",
        fontName : "Manrope"
    }
]

export const setFont = (fontProperties : FontPreferenceType) => {
    for (const property in fontProperties){
        const val : string | number = fontProperties[property as keyof FontPreferenceType] || "noval";
        const stringVal : string = typeof val === "number" ? val + "px" : val;
        document.documentElement.style.setProperty('--font_'+property, stringVal);
    }
}