import { DataMuseRequestType } from "../components/editor/CreativePanel";
import { NoteType, PartCreateType, StoryEditType } from "../types/StoryTypes";
import { UserType } from "../types/UserTypes";
import { LoadEnv } from "./EnvUtils";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IP_INFO_TOKEN = process.env.REACT_APP_IPINFOTOKEN;

type endpoint = `${string}`;

type methods = "POST" | "GET" | "PATCH" | "DELETE" | "PUT";

const request = async (
    endpoint: endpoint,
    method: methods = "GET",
    data: any = {},
    isformdata?: boolean,
    isExternal?: boolean
) => {
    let url = isExternal ? endpoint : API_BASE_URL + endpoint;
    let payload: null | string = isformdata ? data : JSON.stringify(data);

    if (method === "GET") {
        const requestParams = data
            ? `?${Object.keys(data)
                  .map((key) => `${key}=${data[key]}`)
                  .join("&")}`
            : "";
        url += requestParams;
        payload = null;
    }

    const localToken = LoadEnv().auth?.token;

    const auth =
        localToken === null || typeof localToken === "undefined"
            ? ""
            : "Token " + localToken;

    const response = await fetch(url, {
        method: method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: auth,
        },
        body: payload,
    });
    try {
        const json = await response.clone().json();
        if (json && response.ok) {
            return json;
        } else {
            throw json;
        }
    } catch (error) {
        if (response.ok) {
            return { error };
        } else {
            throw { error };
        }
    }
};

export const API = {
    user: {
        login: (email: string, password: string) =>
            request("auth/login", "POST", { email, password }),
        me: () => request("users/me"),
        updateMe: (data: Partial<UserType>) =>
            request("users/me", "PATCH", data),
        fetch: (username: string) => request(`users/${username}`),
    },
    external: {
        getUserIp: () =>
            request(
                `https://ipinfo.io`,
                "GET",
                { token: IP_INFO_TOKEN },
                false,
                true
            ),
        dataMuse: (dict: Partial<DataMuseRequestType>) =>
            request(`https://api.datamuse.com/words`, "GET", dict, false, true),
        dictionaryAPI: (word: string) =>
            request(
                `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`,
                "GET",
                {},
                false,
                true
            ),
    },
    notifications: {
        list: () => request("notifications"),
        see: () => request("notifications/see", "POST"),
        read: (notif: string) => request(`notifications/${notif}/read`, "POST"),
    },
    stories: {
        list: () => request("stories"),
        create: (story: StoryEditType) => request("stories", "POST", story),
        update: (story: StoryEditType, slug: string) =>
            request(`stories/${slug}`, "PATCH", story),
        me: () => request("stories/my_stories"),
        fetch: (slug: string) => request(`stories/${slug}`),
        delete: (slug: string) => request(`stories/${slug}`, "DELETE"),
    },
    parts: {
        list: (slug: string) => request(`stories/${slug}/parts/list_edit`),
        create: (story: string, part: PartCreateType) =>
            request(`stories/${story}/parts`, "POST", part),
        fetch: (story: string, id: string) =>
            request(`stories/${story}/parts/${id}/edit`),
        update: (
            story: string,
            id: string,
            part: {
                title?: string;
                unpublished_content?: string;
                content?: string;
                visibility?: number;
            }
        ) => request(`stories/${story}/parts/${id}`, "PATCH", part),
        delete: (story: string, id: string) =>
            request(`stories/${story}/parts/${id}`, "DELETE"),
    },
    notes: {
        list: (filters: { story?: string; part?: string; color?: string }) =>
            request(`notes`, "GET", filters),
        create: (note: NoteType) => request(`notes`, "POST", note),
        update: (note_id: string, note: NoteType) =>
            request(`notes/${note_id}`, "PATCH", note),
        delete: (note_id: string) => request(`notes/${note_id}`, "DELETE"),
        fetch: (note_id: string) => request(`notes/${note_id}`),
    },
};
