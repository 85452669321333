import { ListType, LocalEnvType, SetLocalEnvType } from "../../types/EnvTypes";
import "../../css/notifications.scss";
import { NotificationType } from "../../types/NotificationTypes";
import { Link } from "raviger";
import { toDateTime, toRelativeTime } from "../../utils/TimeUtils";

export default function Notifications(props: {
    iRef: any;
    notifications: ListType<NotificationType[]> | null;
}): JSX.Element {
    return (
        <div className="w-notif-wrap" ref={props.iRef}>
            <div className="w-notif-header">Notifications</div>
            <div className="w-notif-main">
                {!props.notifications ? (
                    <>
                        {[1, 2, 3].map((m, i) => (
                            <div
                                key={i}
                                className="w-fallback-block"
                                style={{
                                    height: 50,
                                    marginBottom: 10,
                                }}
                            />
                        ))}
                    </>
                ) : props.notifications.count === 0 ? (
                    <div className="w-notif-error">
                        <i className="fad fa-bells" />
                        <div>Your jingle bells have not jingled yet...</div>
                    </div>
                ) : (
                    <>
                        {props.notifications.results.map((notification, i) => {
                            let text;
                            let isIcon = false;
                            let icon;
                            let href = "";

                            switch (notification.type) {
                                case 4:
                                    text = (
                                        <>
                                            <Link
                                                href={`/user/${notification.content.user.username}`}
                                                className="w-notif-link"
                                            >
                                                {
                                                    notification.content.user
                                                        .username
                                                }
                                            </Link>{" "}
                                            started following you!
                                        </>
                                    );
                                    icon = `http://localhost:8000/${notification.content.user.profile_picture}`;
                                    href = `/user/${notification.content.user.username}?notif=${notification.external_id}`;
                                    break;

                                case 12:
                                    text = (
                                        <>
                                            Your report submitted to us on{" "}
                                            {toDateTime(
                                                notification.content.report
                                                    .created_at
                                            )}{" "}
                                            has been judged to be{" "}
                                            {notification.content.report
                                                .judgement
                                                ? "true"
                                                : "false"}
                                            . Thank you for helping us keep this
                                            community safe!
                                        </>
                                    );
                                    isIcon = true;
                                    icon = `flag`;
                                    href = `/report/${notification.content.report.external_id}?notif=${notification.external_id}`;
                                    break;

                                default:
                                    text = "";
                                    break;
                            }

                            return (
                                <Link
                                    href={href}
                                    key={i}
                                    className={
                                        "w-notif-item" +
                                        (notification.read ? "" : " unread")
                                    }
                                    onClick={() =>
                                        props.iRef.current?.classList.toggle(
                                            "show"
                                        )
                                    }
                                >
                                    <div
                                        className="w-notif-icon"
                                        style={
                                            !isIcon
                                                ? {
                                                      backgroundImage: `url(${icon})`,
                                                      backgroundPosition:
                                                          "center",
                                                      backgroundSize: "cover",
                                                  }
                                                : {}
                                        }
                                    >
                                        {isIcon && (
                                            <i className={"far fa-" + icon} />
                                        )}
                                    </div>
                                    <div className="w-notif-content">
                                        {text}
                                        <div className="w-notification-time">
                                            {toRelativeTime(
                                                notification.created_at,
                                                true
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-notif-indicator">
                                        {notification.opened || (
                                            <i className="fas fa-circle" />
                                        )}
                                    </div>
                                </Link>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}
