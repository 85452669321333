import randomColor from "randomcolor";
import { Link } from "raviger";
import { useContext } from "react";
import { LocalEnvContext } from "../../../contexts/env";
import { StoryGenres, StoryType } from "../../../types/StoryTypes";

export default function PreviewModal() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const mc = localEnv.modalContent;

    const story = mc?.type === "STORY" ? mc.story : ({} as StoryType);
    const color = story.cover
        ? "gray"
        : randomColor({ luminosity: "light", seed: story.external_id });

    if (!story) {
        return null;
    }

    return (
        <div className="w-spm-wrap">
            <div className="w-spm-lbar">
                <div
                    className="w-story-item-cover big"
                    style={
                        story.cover
                            ? {
                                  backgroundImage: `url(${story.cover})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                              }
                            : ({
                                  background: color,
                                  "--cover-color": color,
                              } as React.CSSProperties)
                    }
                >
                    <div className="w-story-item-cover-text">{story.title}</div>
                </div>
                <br />
                <b>{story.reads || 0}</b> reads
                <br />
                <b>{story.claps || 0}</b> claps
                <br />
                <b>0 mins</b> reading time
            </div>
            <div className="w-spm-rbar">
                <div className="w-spm-title">{story.title}</div>
                <Link
                    href={`/user/${story.author.username}`}
                    className="w-spm-user-wrap"
                >
                    <div
                        className="w-spm-user-img"
                        style={{
                            backgroundImage: `url(${
                                story.author.profile_picture ||
                                "/media/avatar.png"
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    />
                    <div className="w-spm-user">
                        {story.author.username || "Anonymous"}
                    </div>
                </Link>
                <br />
                <div
                    className="w-story-item-genre"
                    style={
                        {
                            "--genre-color": StoryGenres[story.genre - 1].color,
                        } as React.CSSProperties
                    }
                >
                    {StoryGenres[story.genre - 1].name}
                </div>
                <br />
                <br />
                <div className="w-spm-button-wrap">
                    <Link
                        href={`/story/${story.url}`}
                        className="w-spm-read-button"
                        onClick={() =>
                            setLocalEnv({
                                ...localEnv,
                                modalContent: undefined,
                                modal: undefined,
                            })
                        }
                    >
                        <i className="far fa-play"></i> Read
                    </Link>
                    <a className="w-spm-secondary-button w-spm-add-to-shelf">
                        <i className="far fa-books"></i> Add to Shelf
                    </a>
                </div>
                <br />
                <br />
                <div className="w-spm-desc">{story.description}</div>

                <br />
                <br />
                <b>PARTS</b>
                <br />
                <br />
            </div>
        </div>
    );
}
