import "../../css/sidebar.scss";
import { useContext, useEffect, useState } from "react";
import { API } from "../../utils/ApiUtils";
import { ActiveLink, Link, useMatch, usePath } from "raviger";
import { PartUpdateType, StoryType } from "../../types/StoryTypes";
import { LocalEnvContext } from "../../contexts/env";
import { useQuery } from "react-query";
import SidebarButtons from "./SidebarButtons";

export interface ButtonType {
    name: string;
    buttons: SubButtonType[];
    icon: string;
}

type SubButtonType = (StoryButton | LinkButton) & { wip?: boolean };

type StoryButton = {
    type: "STORY";
    name: string;
    object: StoryType;
    parts: PartUpdateType[] | null;
};

type LinkButton = {
    type: "LINK";
    name: string;
    icon: string;
    link: string;
};

export default function Sidebar() {
    const { data, isError, isLoading } = useQuery("stories", async () => {
        const resp = await API.stories.me();
        const items = resp.results.map((story: StoryType) => {
            const parts_order = story.parts_order;
            const parts = parts_order
                .map((part_id) =>
                    story.parts?.find((p) => p.external_id === part_id)
                )
                .filter((p) => p !== undefined) as PartUpdateType[];
            return {
                type: "STORY",
                name: story.title,
                object: story,
                parts: parts,
            };
        });
        return items;
    });

    const sidebarButtons: ButtonType[] = [
        {
            name: "My Stories",
            buttons: [
                ...(data || []),
                {
                    type: "LINK",
                    name: "New Story",
                    icon: "plus",
                    link: "/create",
                },
            ],
            icon: "books",
        },
        {
            name: "Productivity",
            buttons: [
                {
                    type: "LINK",
                    name: "Notes",
                    icon: "sticky-note",
                    link: "/notes",
                    wip: true,
                },
                {
                    type: "LINK",
                    name: "Kanban",
                    icon: "clipboard-list",
                    link: "/goals",
                    wip: true,
                },
                {
                    type: "LINK",
                    name: "Writing Prompts",
                    icon: "lightbulb",
                    link: "/todo",
                    wip: true,
                },
            ],
            icon: "clipboard-list",
        },
        {
            name: "Analytics",
            buttons: [
                {
                    type: "LINK",
                    name: "Overview",
                    icon: "chart-line",
                    link: "/analytics",
                },
                {
                    type: "LINK",
                    name: "Demographics",
                    icon: "chart-pie",
                    link: "/analytics/demographics",
                },
                {
                    type: "LINK",
                    name: "Comments",
                    icon: "comments",
                    link: "/analytics/comments",
                },
            ],
            icon: "chart-line",
        },
        {
            name: "Payments",
            buttons: [
                {
                    type: "LINK",
                    name: "Revenue",
                    icon: "dollar-sign",
                    link: "/revenue",
                },
                {
                    type: "LINK",
                    name: "Setup Payments",
                    icon: "money-bill-wave",
                    link: "/revenue/setup",
                },
            ],
            icon: "money-bills",
        },
    ];

    return (
        <div className="w-sidebar">
            <Link href="/" className="w-sidebar-logo-wrap">
                <img
                    src="/media/writeroo_circle.svg"
                    className="w-sidebar-logo"
                />
                <div className="w-sidebar-logo-title">Work Desk</div>
            </Link>
            <div className="w-sidebar-nav-wrap">
                <ActiveLink
                    activeClass="active"
                    href="/home"
                    className="w-sidebar-nav-button"
                >
                    <i className={"far fa-gauge"} />
                    Dashboard
                </ActiveLink>
                {sidebarButtons.map((button, index) => {
                    return (
                        <div className="w-sidebar-nav-container" key={index}>
                            <div className="w-sidebar-nav-heading">
                                <i className={"far fa-" + button.icon} />
                                {button.name}
                            </div>
                            <SidebarButtons button={button} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
