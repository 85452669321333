import { usePath } from "raviger";
import { useEffect, useState } from "react";

import AppRouter from "./routers/AppRouter";
import { memberRoutes } from "./routers/AuthorizedRoutes";
import { API } from "./utils/ApiUtils";
import { useLocalEnv } from "./utils/EnvUtils";
import { setFont } from "./utils/FontUtils";
import { changeTheme, Themes, useThemeDetector } from "./utils/ThemeUtils";
import { logoutUser } from "./utils/UserUtils";

import { LocalEnvContext } from "./contexts/env";
import { useMutation } from "react-query";
import { UserType } from "./types/UserTypes";
import { useAlert } from "react-alert";
import { TitleContext } from "./contexts/title";
import Shortcuts, { ShortCut } from "./utils/Shortcuts";

function App() {
    const [localEnv, setLocalEnv] = useLocalEnv();
    const [title, setTitle] = useState("");
    const path = usePath();
    const alert = useAlert();
    const isDarkTheme = useThemeDetector();

    const getUserDetails = async () => {
        if (localEnv.auth) {
            try {
                const user = await API.user.me();
                setLocalEnv({
                    ...localEnv,
                    auth: {
                        ...localEnv.auth,
                        ...user,
                    },
                });
            } catch (error) {
                logoutUser(localEnv, setLocalEnv);
            }
        } else {
            logoutUser(localEnv, setLocalEnv);
        }
    };

    useEffect(() => {
        if (
            path &&
            memberRoutes.includes(path) &&
            path !== "/login" &&
            !localEnv.auth?.token
        ) {
            window.location.href = "/login?ref=" + window.location.href;
        }
        if (
            localEnv.theme &&
            Themes.filter((t) => t.name === localEnv.theme).length === 1
        ) {
            changeTheme(localEnv.theme);
        } else {
            setLocalEnv({
                ...localEnv,
                theme: isDarkTheme ? "dark" : "light",
            });
        }

        if (path !== "/login") {
            getUserDetails();
        }

        // eslint-disable-next-line
    }, [path, isDarkTheme]);

    useEffect(() => {
        changeTheme(localEnv.theme || "light");
        localEnv.font && setFont(localEnv.font);
    }, [localEnv.theme, localEnv.font, localEnv.lowhardware]);

    const userMutation = useMutation(
        (q: Partial<UserType>) => {
            return API.user.updateMe(q);
        },
        {
            onError: (error) => {
                alert.error("Could not update user");
            },
        }
    );

    useEffect(() => {
        let toMutateAuth;
        if (localEnv.auth?.token) {
            const { token, ...newauth } = localEnv.auth as any;
            toMutateAuth = newauth;
        } else {
            toMutateAuth = localEnv.auth;
        }
        //userMutation.mutate(toMutateAuth);
    }, [localEnv.auth?.badges_order, localEnv.auth?.notes_order]);

    const SHORTCUTS: ShortCut[] = [
        {
            key: "alt+t",
            handler: () => {
                const currentTheme = localEnv.theme;
                const themeIndex = Themes.findIndex(
                    (t) => t.name === currentTheme
                );
                const nextTheme = Themes[(themeIndex + 1) % Themes.length];
                setLocalEnv({ ...localEnv, theme: nextTheme.name });
                alert.info(`Switched to ${nextTheme.displayName}.`);
            },
            dependencies: [localEnv],
        },
    ];

    return (
        <LocalEnvContext.Provider value={[localEnv, setLocalEnv]}>
            <TitleContext.Provider value={[title, setTitle]}>
                <Shortcuts shortcuts={SHORTCUTS} />
                <AppRouter />
            </TitleContext.Provider>
        </LocalEnvContext.Provider>
    );
}

export default App;
