import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";

export const logoutUser = (
    localEnv: LocalEnvType,
    setLocalEnv: SetLocalEnvType
) => {
    const { auth, ...newLocal } = localEnv;
    setLocalEnv(newLocal);
    window.location.href = "/login?ref=" + window.location.href;
};
