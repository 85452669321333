import { ReactNode, useContext } from "react";
import { useAlert } from "react-alert";
import {
    FacebookIcon,
    FacebookShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    RedditIcon,
    RedditShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { LocalEnvContext } from "../../../contexts/env";

export default function ShareModal() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const alerts = useAlert();

    const mc = localEnv.modalContent;
    const url = mc?.type === "SHARE" ? mc.link : "";
    const iconProps = {
        round: true,
        size: 70,
    };
    return (
        <div style={{ textAlign: "center" }}>
            <div className="w-modal-panel-title-max">Share</div>
            <br />
            <div className="w-modal-share-wrapper">
                <button
                    className="w-modal-share-button"
                    onClick={() => {
                        navigator.clipboard.writeText(url);
                        alerts.success("Link Copied");
                    }}
                >
                    <i className="far fa-copy" />
                </button>
                <WhatsappShareButton url={url}>
                    <WhatsappIcon {...iconProps} />
                </WhatsappShareButton>
                <FacebookShareButton url={url} hashtag="writeroo">
                    <FacebookIcon {...iconProps} />
                </FacebookShareButton>
                <TwitterShareButton url={url}>
                    <TwitterIcon {...iconProps} />
                </TwitterShareButton>
                <RedditShareButton url={url}>
                    <RedditIcon {...iconProps} />
                </RedditShareButton>
                <TumblrShareButton url={url}>
                    <TumblrIcon {...iconProps} />
                </TumblrShareButton>
            </div>
        </div>
    );
}
