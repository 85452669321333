import { ReactNode } from "react";
import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";
import Header from "./header/Header";
import "../css/common.scss";
import CommonModal from "./modals/CommonModal";
import Footer from "./footer/Footer";
import Sidebar from "./header/Sidebar";

export default function AppContainer(props: { children: ReactNode }) {
    return (
        <>
            <CommonModal />
            <Sidebar />
            <Header />
            <div className="w-page">{props.children}</div>
            {/*<Footer localEnv={props.localEnv} setLocalEnv={props.setLocalEnv}/>*/}
        </>
    );
}
