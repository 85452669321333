import FontContainer from "./FontContainer";

export default function FontSettings() {
    return (
        <>
            <FontContainer />
            <br />
            <br />
            <div className="w-modal-panel-title">Example</div>
            <div className="w-font-dynamic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
                Fusce interdum ullamcorper eros.
                <br />
                Mauris nec massa sagittis, fermentum justo id, congue magna.
            </div>
        </>
    );
}
