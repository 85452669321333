import { LocaleContentType } from "../Locales";

export const LocaleEnglish : LocaleContentType = {
    header : {
        search : {
            placeholder : "Into the wilderness"
        },
        userMenu : {
            myAccount : "My Account",
            myShelves : "My Shelves",
            settings : "Settings",
            logout : "Logout"
        }
    },
    settings : {
        tabs : {
            general : "General",
            generalContent : {
                displayLanguage : "Display Language",
                storyLanguage : "Story Language",
                storyLanguageSub : "You will be shown stories in the following languages",
                explicitContent : "Explicit Content",
                explicitContentSub : "Show Explicit Content"
            },
            themes : "Themes",
            themeContent : {
                theme : "Theme",
                lowhardware : "Low Hardware Mode",
                autoMood : "Auto Mood",
                autoMoodSub : "changes your theme depending upon the time of the day.",
            }
        }
    }
}