import { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { LocalEnvContext } from "../../contexts/env";
import { MCWarningType } from "../../types/ModalTypes";

export default function WarningModal() {
    const [warning, setWarning] = useState<MCWarningType | null>(null);

    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const alert = useAlert();

    useEffect(() => {
        const mc = localEnv.modalContent;
        if (mc?.type === "WARNING") {
            setWarning(mc);
        }
    }, [localEnv.modalContent]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="w-modal-panel-title-icon">
                <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div className="w-modal-panel-w-title">
                {warning?.title || "Warning"}
            </div>
            <div className="w-modal-panel-w-content">{warning?.content}</div>
            <br />
            <div>
                <button
                    className="w-mpw-cancel"
                    onClick={() => {
                        setLocalEnv({
                            ...localEnv,
                            modalContent: undefined,
                            modal: undefined,
                        });
                    }}
                >
                    Cancel
                </button>
                <button
                    className="w-mpw-proceed"
                    onClick={() => {
                        try {
                            warning?.buttonAction();
                            setLocalEnv({
                                ...localEnv,
                                modal: undefined,
                                modalContent: undefined,
                            });
                        } catch (error) {
                            alert.error("error", error as any);
                        }
                    }}
                >
                    Proceed
                </button>
            </div>
        </div>
    );
}
