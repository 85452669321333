import { useEffect, useState } from "react";
import LoadingTips from "../locale/loadingTips.json";
import LoadingTipsIn from "../locale/loadingTips.in.json";
import { API } from "../utils/ApiUtils";
import { LoadEnv, StoreEnv } from "../utils/EnvUtils";

export default function RouterFallback() {
    const [loadingTip, setLoadingTip] = useState<string>("");

    useEffect(() => {
        setupLoginScreen();
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const setupLoginScreen = async () => {
        let allTips = LoadingTips;
        const env = LoadEnv();
        let uCountry = env.country;
        if (!uCountry) {
            uCountry = undefined;
            uCountry = (await getUserIpInfo()).country;
            StoreEnv("country", uCountry);
        }
        if (uCountry) {
            switch (uCountry) {
                case "IN":
                    allTips = allTips.concat(LoadingTipsIn);
                    break;
                default:
                    break;
            }
            const loadingTip =
                allTips[Math.floor(Math.random() * allTips.length)];
            setLoadingTip(loadingTip);
        }
    };

    const getUserIpInfo = async () => {
        try {
            const userIPInfo = await API.external.getUserIp();
            return userIPInfo;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-loading-screen">
            <div className="w-loading-main">
                <div className="w-loading-main-wrap">
                    <div className="w-loading-icon" />
                    {/*
                    <br/>
                    <br/>
                    <div className="w-loading-progress-wrap">
                        <div className="w-loading-progress"/>
                    </div>
                    */}
                    <br />
                    <br />
                    <div
                        className="w-loading-tip"
                        dangerouslySetInnerHTML={{ __html: loadingTip }}
                    ></div>
                    {/*<input type="hidden" id="w_progress_width" value="0"/>*/}
                </div>
            </div>
        </div>
    );
}
