import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { AlertTemplate } from "./components/common/Alert";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
const alertOptions = {
    position: positions.BOTTOM_RIGHT,
    timeout: 3000,
    transition: transitions.FADE,
    template: AlertTemplate,
};

root.render(
    //<React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <AlertProvider {...alertOptions}>
            <App />
        </AlertProvider>
    </QueryClientProvider>

    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
