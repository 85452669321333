import { useContext } from "react";
import { LocalEnvContext } from "../../../contexts/env";
import { Themes } from "../../../utils/ThemeUtils";

export default function ThemeSettings() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    return (
        <>
            <div className="w-modal-panel-title">Theme</div>
            <div className="w-modal-theme-wrap">
                {Themes.map((theme, i) => {
                    return (
                        <label
                            key={i}
                            className={
                                "w-modal-theme-box " +
                                (theme.name === localEnv.theme && "selected")
                            }
                        >
                            <div className="w-modal-theme-box-canvas">
                                <div
                                    className="w-tbc-page"
                                    style={{
                                        backgroundColor: theme.scheme.primary,
                                    }}
                                >
                                    <div
                                        className="w-tbc-text"
                                        style={{
                                            backgroundColor:
                                                theme.scheme.primaryFont,
                                            width: 70,
                                        }}
                                    />
                                    <div
                                        className="w-tbc-text"
                                        style={{
                                            backgroundColor:
                                                theme.scheme.primaryFont,
                                            width: 40,
                                        }}
                                    />
                                    <br />
                                    <div
                                        className="w-tbc-text"
                                        style={{
                                            backgroundColor:
                                                theme.scheme.secondary,
                                            width: 40,
                                            height: 40,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-modal-theme-box-span">
                                {theme.displayName}
                            </div>
                            <input
                                type="radio"
                                name="theme"
                                value={theme.name}
                                onChange={(e) => {
                                    setLocalEnv({
                                        ...localEnv,
                                        theme: e.target.value,
                                    });
                                }}
                            />
                        </label>
                    );
                })}
            </div>
            <br />
            <br />
            <div>
                Low Hardware Mode: &nbsp;
                <label className="w-modal-switch">
                    <input
                        type="checkbox"
                        checked={localEnv.lowhardware}
                        onChange={(e) => {
                            setLocalEnv({
                                ...localEnv,
                                lowhardware: !localEnv.lowhardware,
                            });
                        }}
                    />
                    <span className="w-modal-switch-slider"></span>
                </label>
            </div>
            <br />
            <br />
            <div className="w-modal-panel-title">
                Auto Mood{" "}
                <sup>
                    <small>Beta</small>
                </sup>
            </div>
            <small>
                <i className="far fa-info-circle"></i> changes your theme
                depending upon the time of the day.
            </small>
            <br />
            <br />
            <div>
                Auto Mood: &nbsp;
                <label className="w-modal-switch">
                    <input type="checkbox" />
                    <span className="w-modal-switch-slider"></span>
                </label>
            </div>
        </>
    );
}
