import { Link } from "raviger";
import { MouseEventHandler, useContext } from "react";
import { LocalEnvContext } from "../../contexts/env";
import { LocalEnvType, SetLocalEnvType } from "../../types/EnvTypes";
import { logoutUser } from "../../utils/UserUtils";

type MenuButton = [
    string,
    string,
    MouseEventHandler<HTMLButtonElement> | undefined,
    false
];

type MenuLink = [string, string, string, true];
type MenuItem = MenuButton | MenuLink;

export default function HeaderDropdown(props: { iRef: any; toggle: any }) {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);
    if (localEnv.auth) {
        const MenuButtons: MenuItem[] = [
            ["Writeroo", "arrow-left", `https://www.writeroo.in/`, true],
            [
                "Settings",
                "cog",
                () =>
                    setLocalEnv({
                        ...localEnv,
                        modal: "settings",
                    }),
                false,
            ],
            [
                "Logout",
                "sign-out-alt",
                () => logoutUser(localEnv, setLocalEnv),
                false,
            ],
        ];

        return (
            <div className="w-header-submenu" ref={props.iRef}>
                {MenuButtons.map((item, i) => {
                    return item[3] ? (
                        <Link
                            href={typeof item[2] === "string" ? item[2] : ""}
                            className="w-header-submenu-button"
                            key={i}
                            onClick={() => props.toggle()}
                        >
                            <i className={"far fa-" + item[1]}></i> {item[0]}
                        </Link>
                    ) : (
                        <button
                            className="w-header-submenu-button"
                            onClick={item[2]}
                            key={i}
                        >
                            <i className={"far fa-" + item[1]}></i> {item[0]}
                        </button>
                    );
                })}
            </div>
        );
    } else {
        return <div></div>;
    }
}
