import { useHotkeys } from "react-hotkeys-hook";

export type ShortCut = {
    key: string;
    handler: () => void;
    dependencies?: any[];
    options?: any;
};

export default function Shortcuts(props: { shortcuts: ShortCut[] }) {
    props.shortcuts.forEach((shortcut) => {
        useHotkeys(
            shortcut.key,
            shortcut.handler,
            shortcut.options,
            shortcut.dependencies
        );
    });

    return null;
}
