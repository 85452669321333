import { navigate } from "raviger";
import { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useMutation, useQueryClient } from "react-query";
import { LocalEnvContext } from "../../../contexts/env";
import { PartCreateType, StoryType } from "../../../types/StoryTypes";
import { API } from "../../../utils/ApiUtils";

export default function CreatePartModal() {
    const [part, setPart] = useState<PartCreateType>({ title: "" });
    const [story, setStory] = useState<StoryType | null>(null);
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const queryClient = useQueryClient();

    const alert = useAlert();

    const mutateQuery = useMutation(
        (part: PartCreateType) => API.parts.create(story?.url || "", part),
        {
            onSuccess: (data) => {
                alert.success("Part created");
                navigate("/story/" + story?.url + "/" + data.url);
                queryClient.refetchQueries("stories");
            },
            onError: (error: any) => {
                alert.error(error.title || "Error");
            },
        }
    );

    useEffect(() => {
        const mc = localEnv.modalContent;
        if (mc?.type === "STORY") {
            setStory(mc.story);
        }
    }, []);

    const createPart = async () => {
        mutateQuery.mutate(part);
    };

    return (
        <div
            style={{
                textAlign: "center",
            }}
        >
            {story?.title || "Loading..."}
            <div className="w-modal-panel-title-max">Create Part</div>
            <br />
            <input
                type="text"
                placeholder="Part Title"
                value={part.title}
                onChange={(e) => setPart({ ...part, title: e.target.value })}
                className="w-modal-input input-max"
            />
            <br />
            <br />
            <button
                className="w-button"
                onClick={() => {
                    try {
                        createPart();
                        setLocalEnv({
                            ...localEnv,
                            modal: undefined,
                            modalContent: undefined,
                        });
                    } catch {
                        alert.error("Error");
                    }
                }}
            >
                Create Part
            </button>
        </div>
    );
}
