import { UserType } from "./UserTypes";

export interface StoryType {
    external_id: string;
    url: string;
    title: string;
    author: UserType;
    description?: string;
    cover?: string;
    genre: number;
    tags: string[];
    visibility: number;
    language: string;
    parts_order: string[];
    explicit: boolean;
    finished: boolean;
    published_on: string;
    claps: number;
    reads: number;
    comments: number;
    parts?: PartInfoType[];
}

export interface StoryEditType {
    external_id?: string;
    url: string;
    title: string;
    description?: string;
    cover?: string;
    genre: number;
    tags: string[];
    visibility: number;
    language: string;
    parts_order: string[];
    explicit: boolean;
    finished: boolean;
    allow_comments: boolean;
}

type GenreType = {
    name: string;
    color: string;
};

export interface PartInfoType {
    external_id: string;
    url: string;
    title: string;
    published_on?: string;
}
export type PartUpdateType = {
    story_object?: StoryType;
    url: string;
    content: string;
    unpublished_content: string;
    visibility?: number;
    last_worked_on?: string;
    created_at?: string;
} & Partial<PartInfoType>;

export type PartCreateType = {
    title: string;
};

export type NoteType = {
    external_id?: string;
    story?: string;
    part?: string;
    title?: string;
    description?: string;
    created_at?: string;
    line?: string;
    color?: string;
};

export const StoryGenres: GenreType[] = [
    {
        name: "Science Fiction",
        color: "#00008B",
    },
    {
        name: "Fantasy",
        color: "#0000E0",
    },
    {
        name: "Poetry",
        color: "#A333D5",
    },
    {
        name: "Fan Fiction",
        color: "#0074FF",
    },
    {
        name: "General Fiction",
        color: "#05FFF9",
    },
    {
        name: "Adventure",
        color: "#53FFAA",
    },
    {
        name: "Teen Fiction",
        color: "#F490E1",
    },
    {
        name: "Humour",
        color: "#F2FF0C",
    },
    {
        name: "Folktale",
        color: "#FFBA00",
    },
    {
        name: "Mythology",
        color: "#FF7F00",
    },
    {
        name: "Romance",
        color: "#FF2100",
    },
    {
        name: "Mystery / Thriller",
        color: "#D70000",
    },
    {
        name: "Random",
        color: "#7F7F7F",
    },
    {
        name: "Horror",
        color: "#000000",
    },
];

export const VisibilityType = ["Private", "Unlisted", "Public"];
