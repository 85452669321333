export const toUnixTime = (time : string) => {
    return Math.floor(new Date(time).getTime() / 1000);
}

export const toDateTime = (timeString : string, includeTime = false) => {
    const time = new Date(timeString);
    const year = time.getFullYear();
    const month = time.getMonth()+1;
    const date = time.getDate();
    let hour = time.getHours();
    const minutes = time.getMinutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
    
    const suf = hour > 11 ? "PM" : "AM";

    if(hour > 12){
        hour -= 12;
    }

    return `${date}/${month}/${year}` + (includeTime ? ` ${hour}:${minutes} ${suf}` : "");
}

export const toRelativeTime = (time : string, ago=false) => {
    const presentunix = Math.floor(Date.now() / 1000);
    const difference = presentunix - toUnixTime(time);
    let newTime;
    let suffix;
    if(difference > 31536000){
        newTime = Math.floor(difference / 31536000);
        suffix = 'y'; 
    }else if(difference > 86400){
        newTime = Math.floor(difference / 86400);
        suffix = 'd'; 
    }else if(difference > 3600){
        newTime = Math.floor(difference / 3600);
        suffix = 'h'; 
    }else if(difference > 60){
        newTime = Math.floor(difference / 60);
        suffix = 'm'; 
    }else {
        newTime = '';
        suffix = 'now'; 
    }
    return newTime+suffix+(suffix !== 'now' && ago ? " ago" : '');
}
/*
function unixtoFriendlyLong(unix){
    var presentunix = Math.floor(Date.now() / 1000);
    var difference = presentunix - unix;
    if(difference > 31536000){
        var time = Math.floor(difference / 31536000);
        var suffix = ' year'+pluralCheck(time,1)+' ago'; 
    }else if(difference > 86400){
        var time = Math.floor(difference / 86400);
        var suffix = ' day'+pluralCheck(time,1)+' ago'; 
    }else if(difference > 3600){
        var time = Math.floor(difference / 3600);
        var suffix = ' hour'+pluralCheck(time,1)+' ago'; 
    }else if(difference > 60){
        var time = Math.floor(difference / 60);
        var suffix = ' minute'+pluralCheck(time,1)+' ago'; 
    }else {
        var time = '';
        var suffix = 'now'; 
    }
    return time+suffix;
}
*/