import { useContext, useEffect, useState } from "react";
import { LocalEnvContext } from "../../../contexts/env";
import { Locales } from "../../../locale/Locales";

export default function GeneralSettings() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const [storyLanguagesState, setStoryLanguages] = useState<string[] | null>(
        null
    );

    useEffect(() => {
        //get user's preferred story languages through api
        setStoryLanguages([]);
    }, []);

    return (
        <>
            <div className="w-modal-panel-title">Display Language</div>
            <select
                className="w-modal-panel-select"
                value={localEnv.locale}
                onChange={(e) => {
                    setLocalEnv({
                        ...localEnv,
                        locale: e.target.value,
                    });
                }}
            >
                {Locales.map((locale, i) => {
                    return (
                        <option value={locale.id} key={i}>
                            {locale.nativeName}
                        </option>
                    );
                })}
            </select>
        </>
    );
}
