import { useContext, useEffect, useState } from "react";
import { LocalEnvContext } from "../contexts/env";
import { TitleContext } from "../contexts/title";
import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";

const LocalEnvAddress =
    typeof process.env.REACT_APP_LOCAL_ENV === "string"
        ? process.env.REACT_APP_LOCAL_ENV
        : "WorkdeskLocalEnv";

export const LoadEnv: () => LocalEnvType = () => {
    const getStore = localStorage.getItem(LocalEnvAddress);
    return getStore ? JSON.parse(getStore) : {};
};

export const StoreEnv = (envName: keyof LocalEnvType, envValue: any) => {
    const LocalEnv: LocalEnvType = LoadEnv();
    LocalEnv[envName] = envValue;

    localStorage.setItem(LocalEnvAddress, JSON.stringify(LocalEnv));
};

export const DeleteEnv = (envName: keyof LocalEnvType) => {
    const LocalEnv: LocalEnvType = LoadEnv();

    if (LocalEnv[envName]) {
        delete LocalEnv[envName];
    }

    localStorage.setItem(LocalEnvAddress, JSON.stringify(LocalEnv));
};

export const useLocalEnv: () => [LocalEnvType, SetLocalEnvType] = () => {
    const [value, setValue] = useState(() => {
        return LoadEnv();
    });

    useEffect(() => {
        localStorage.setItem(LocalEnvAddress, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
};

export function PageTitle(props: { title: string; headerTitle?: string }) {
    const { title, headerTitle } = props;
    const [t, sT] = useContext(TitleContext);
    useEffect(() => {
        document.title = title + " - Work Desk - Writeroo";
        sT(headerTitle || title);

        return () => {
            document.title = "Work Desk - Writeroo";
            sT("");
        };
    }, [title, headerTitle]);

    return null;
}
