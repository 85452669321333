import { LocaleContentType } from "../Locales";

export const LocaleHindi : LocaleContentType = {
    header : {
        search : {
            placeholder : "सफर पर निकलें"
        },
        userMenu : {
            myAccount : "मेरा खाता",
            myShelves : "मेरी शेल्व्ज़",
            settings : "सेटिंग्स",
            logout : "लॉग आउट"
        }
    },
    settings : {
        tabs : {
            general : "सामान्य",
            generalContent : {
                displayLanguage : "प्रदर्शित भाषा",
                storyLanguage : "कहानी की भाषा",
                storyLanguageSub : "आपको निम्नलिखित भाषाओं में कहानियां दिखाई जाएंगी",
                explicitContent : "एक्सप्लिसित कोंटेत्न",
                explicitContentSub : "एक्सप्लिसित कंटेंट दिखाएं"
            },
            themes : "थीम्स",
            themeContent : {
                theme : "थीम",
                lowhardware : "कम हार्डवेयर मोड",
                autoMood : "ऑटो मूड",
                autoMoodSub : "दिन के समय के आधार पर अपनी थीम बदल देता है।",
            }
        }
    }
}