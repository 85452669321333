import { useContext, useEffect, useRef, useState } from "react";
import "../../css/modals.scss";
import { ModalType } from "../../types/ModalTypes";
import GeneralSettings from "./settings/GeneralSettings";
import FontSettings from "./settings/FontSettings";
import ThemeSettings from "./settings/ThemeSettings";
import ShareModal from "./share/ShareModal";
import PreviewModal from "./story/PreviewModal";
import CreatePartModal from "./parts/CreatePart";
import WarningModal from "./Warning";
import { LocalEnvContext } from "../../contexts/env";

export default function CommonModal() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    const modalRef = useRef<HTMLDivElement>(null);

    const [modal, setModal] = useState<ModalType | null>(null);
    const [modalPanel, setModalPanel] = useState(0);

    useEffect(() => {
        if (localEnv.modal) {
            const chosenModal = modals.filter(
                (m) => m.name === localEnv.modal
            )[0];
            setModal(chosenModal);
            modalRef.current?.classList.add("show");
            document.body.style.overflow = "hidden";
        } else {
            modalRef.current?.classList.remove("show");
            document.body.style.overflow = "auto";
        }
        // eslint-disable-next-line
    }, [localEnv.modal]);

    const modals: ModalType[] = [
        {
            name: "settings",
            panels: {
                display: true,
                buttons: [
                    {
                        name: "General",
                        icon: "cog",
                        content: <GeneralSettings />,
                    },
                    {
                        name: "Themes",
                        icon: "paint-roller",
                        content: <ThemeSettings />,
                    },
                    {
                        name: "Fonts",
                        icon: "font",
                        content: <FontSettings />,
                    },
                ],
            },
        },
        {
            name: "share",
            panels: {
                display: false,
                content: <ShareModal />,
            },
        },
        {
            name: "preview",
            panels: {
                display: false,
                content: <PreviewModal />,
            },
        },
        {
            name: "create-part",
            panels: {
                display: false,
                content: <CreatePartModal />,
            },
        },
        {
            name: "warning",
            panels: {
                display: false,
                content: <WarningModal />,
            },
        },
    ];

    return (
        <div className="w-modal-wrap" ref={modalRef}>
            <div className="w-modal-container">
                <button
                    className="w-modal-close"
                    onClick={() => {
                        setLocalEnv({
                            ...localEnv,
                            modal: undefined,
                        });
                        setModalPanel(0);
                    }}
                >
                    <i className="far fa-long-arrow-left"></i>
                </button>
                {modal && (
                    <div className="w-modal-main">
                        {modal.panels.display && (
                            <div className="w-modal-sidebar">
                                {modal.panels.buttons.map((button, i) => {
                                    return (
                                        <button
                                            key={i}
                                            className={
                                                "w-modal-sidebar-button " +
                                                (modalPanel === i
                                                    ? "select"
                                                    : "")
                                            }
                                            onClick={() => setModalPanel(i)}
                                        >
                                            <i
                                                className={
                                                    "far fa-" + button.icon
                                                }
                                            ></i>
                                            {button.name}
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                        <div className="w-modal-panel-wrap">
                            <div className="w-modal-panel">
                                {modal.panels.display
                                    ? modal.panels.buttons[modalPanel].content
                                    : modal.panels.content}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
