import "../../css/alert.scss";

export const AlertTemplate = ({ style, options, message, close } : any) => {

    type IconTypeArray = [string, [string, string]]

    type IconType = {
        info : IconTypeArray,
        success : IconTypeArray,
        error : IconTypeArray,
    }
    
    const icons : IconType = {
        info : ["info", ["dodgerblue", "rgba(30, 143, 255, 0.2)"]],
        success : ["check", ["green", "rgba(0, 128, 0, 0.2)"]],
        error : ["times", ["red", "rgba(255, 0, 0, 0.2)"]]
    }

    return <div style={style} className="w-alert">
        <div>
            <div className="w-alert-icon" style={{color : icons[options.type as keyof IconType][1][0], background : icons[options.type as keyof IconType][1][1]}}>
                <i className={`far fa-${icons[options.type as keyof IconType][0]}`} />
            </div>
        </div>
        <div>
            {message}
        </div>
        <div>
            <button onClick={close} className="w-alert-close">
                <i className="far fa-times"/>
            </button>
        </div>
    </div>
}
