import { ListType } from "../../types/EnvTypes";
import "../../css/header.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { Themes } from "../../utils/ThemeUtils";
import { Link } from "raviger";
import HeaderDropdown from "./HeaderDropdown";
import Notifications from "./Notifications";
import { NotificationType } from "../../types/NotificationTypes";
import { API } from "../../utils/ApiUtils";
import { LocalEnvContext } from "../../contexts/env";
import { TitleContext } from "../../contexts/title";

export default function Header() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);
    const [title, setTitle] = useContext(TitleContext);
    const logoRef = useRef<HTMLImageElement | null>(null);
    const sideBarRef = useRef<HTMLDivElement | null>(null);
    const headerRef = useRef<HTMLDivElement | null>(null);

    const notifRef = useRef<HTMLDivElement | null>(null);

    const [notifications, setNotifications] = useState<ListType<
        NotificationType[]
    > | null>(null);

    useEffect(() => {
        getNotifications();
        const timeout = setInterval(() => {
            getNotifications();
        }, 5000);
        clearInterval(timeout); //remove this once ws is set up

        return () => clearInterval(timeout);
    }, []);

    const getNotifications = async () => {
        try {
            const fetchedNotifs = await API.notifications.list();
            if (fetchedNotifs) {
                setNotifications(fetchedNotifs);
            }
        } catch (error) {
            console.log("Error fetching notifications", error);
        }
    };

    const triggerNotifSeenApi = async () => {
        return await API.notifications.see();
    };

    useEffect(() => {
        if (logoRef && logoRef.current) {
            logoRef.current.src =
                localEnv.theme &&
                Themes.filter((t) => t.name === localEnv.theme)[0].type ===
                    "dark"
                    ? "/media/writeroo_white.svg"
                    : "/media/writeroo_black.svg";
        }
    }, [localEnv.theme]);

    useEffect(() => {
        if (notifications) {
            const notiflen = notifications.results.filter(
                (r) => r.opened === false
            ).length;
            setLocalEnv({
                ...localEnv,
                notifications: notiflen,
            });
        }
    }, [notifications]);

    const toggleMenuBar = () => {
        sideBarRef.current?.classList.toggle("show");
    };

    const toggleNotifTab = () => {
        notifRef.current?.classList.toggle("show");
        notifRef.current?.classList.contains("show") && triggerNotifSeenApi();
    };

    return (
        <>
            <header
                className={`w-header ${
                    localEnv.editMode ? `w-header-collapsible` : ""
                }`}
                ref={headerRef}
            >
                {/*<div className="w-header-search-wrap">
                    <input type="text" className="w-header-search-bar" placeholder="Search" />
                    <div className="w-header-search-result-wrap">

                    </div>
                </div>*/}
                <div className="w-header-title">
                    {title.substring(0, 50)}
                    {title.length > 50 && "..."}
                </div>

                <div className="w-header-user-wrap">
                    {localEnv.auth?.token ? (
                        <>
                            <div className="w-header-notif-pos-wrap">
                                <button
                                    className="w-header-nav-button w-header-notif-button"
                                    title="Notifications"
                                    onClick={() => toggleNotifTab()}
                                >
                                    {notifications &&
                                        notifications.results.filter(
                                            (r) => r.opened === false
                                        ).length > 0 && (
                                            <div className="w-notif-num-wrap">
                                                {notifications.results.filter(
                                                    (r) => r.opened === false
                                                ).length > 10
                                                    ? "9+"
                                                    : notifications.results.filter(
                                                          (r) =>
                                                              r.opened === false
                                                      ).length}
                                            </div>
                                        )}
                                    <i className="far fa-bell"></i>
                                </button>
                                <Notifications
                                    iRef={notifRef}
                                    notifications={notifications}
                                />
                            </div>
                            <button
                                className="w-header-user-button"
                                onClick={() => toggleMenuBar()}
                            >
                                <div
                                    className="w-header-user-img"
                                    style={{
                                        background: `url(${
                                            localEnv.auth.profile_picture ||
                                            "/media/avatar.png"
                                        })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}
                                />
                                <div className="w-header-username">
                                    {localEnv.auth.username}
                                </div>
                                <div className="w-header-drop">
                                    <i className="far fa-angle-down"></i>
                                </div>
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </header>
            <HeaderDropdown iRef={sideBarRef} toggle={toggleMenuBar} />
        </>
    );
}
