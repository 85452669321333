import { ActiveLink, Link } from "raviger";
import { useContext } from "react";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";
import { LocalEnvContext } from "../../contexts/env";
import { API } from "../../utils/ApiUtils";
import { ButtonType } from "./Sidebar";

export default function SidebarButtons(props: { button: ButtonType }) {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);
    const path = window.location.href.split(window.location.origin)[1];
    const { button } = props;
    const queryClient = useQueryClient();

    const mutationQuery = useMutation(
        (q: { url: string; parts_order: string[] }) =>
            API.stories.update({ parts_order: q.parts_order } as any, q.url),
        {
            onSuccess: (data) => {
                queryClient.setQueryData("stories", (oldData: any) => {
                    const newStories = oldData.map((story: any) => {
                        if (story.object.external_id === data.external_id) {
                            const parts_order = data.parts_order;
                            const parts = parts_order
                                .map((part_id: string) =>
                                    story.parts?.find(
                                        (p: any) => p.external_id === part_id
                                    )
                                )
                                .filter((p: any) => p !== undefined);
                            return {
                                ...story,
                                object: data,
                                parts: parts,
                            };
                        } else {
                            return story;
                        }
                    });
                    return newStories;
                });
            },
        }
    );

    return (
        <>
            {button.buttons.map((subButton, index) => {
                switch (subButton.type) {
                    case "STORY": {
                        const getParts = async (
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                            const parent = e.currentTarget.parentElement;
                            parent?.classList.toggle("w-sidebar-open");
                        };

                        const reorder = (
                            list: string[],
                            startIndex: number,
                            endIndex: number
                        ) => {
                            const A = [...list];
                            const x = startIndex;
                            const y = endIndex;
                            A[x] = A.splice(y, 1, A[x])[0];
                            return A;
                        };

                        const handleReorder = (result: DropResult) => {
                            if (result.destination) {
                                const items = reorder(
                                    subButton.object.parts_order,
                                    result.source.index,
                                    result.destination.index
                                );
                                mutationQuery.mutate({
                                    url: subButton.object.url,
                                    parts_order: items,
                                });
                            }
                        };

                        return (
                            <div
                                key={index}
                                className={
                                    path.split("/")[1] === "story" &&
                                    path.split("/")[2] === subButton.object.url
                                        ? "w-sidebar-open"
                                        : ""
                                }
                            >
                                <div
                                    className="w-sidebar-nav-button w-nav-collapsible"
                                    key={index}
                                    onClick={(e) => getParts(e)}
                                >
                                    <i className="far fa-chevron-right w-open-icon" />
                                    {subButton.name}
                                    <Link
                                        href={"/story/" + subButton.object.url}
                                        className="w-snb-cog"
                                    >
                                        <i className="far fa-cog"></i>
                                    </Link>
                                </div>
                                <div className="w-sidebar-parts-list">
                                    <DragDropContext onDragEnd={handleReorder}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {subButton.parts &&
                                                        subButton.parts.map(
                                                            (part, i) => {
                                                                let iconType =
                                                                    "";
                                                                const visibilityName =
                                                                    [
                                                                        "Private",
                                                                        "Unlisted",
                                                                        "Public",
                                                                    ];
                                                                switch (
                                                                    part.visibility
                                                                ) {
                                                                    case 1:
                                                                        iconType =
                                                                            "far";
                                                                        break;
                                                                    case 2:
                                                                        iconType =
                                                                            "fad";
                                                                        break;
                                                                    case 3:
                                                                        iconType =
                                                                            "fas";
                                                                        break;
                                                                }
                                                                return (
                                                                    <Draggable
                                                                        key={i}
                                                                        draggableId={`${i}`}
                                                                        index={
                                                                            i
                                                                        }
                                                                    >
                                                                        {(
                                                                            provided
                                                                        ) => (
                                                                            <ActiveLink
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                href={`/story/${subButton.object.url}/${part.url}`}
                                                                                activeClass="active"
                                                                                className="w-sidebar-nav-button-parts"
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className={`${iconType} fa-circle`}
                                                                                    title={
                                                                                        visibilityName[
                                                                                            part.visibility ||
                                                                                                0
                                                                                        ]
                                                                                    }
                                                                                />
                                                                                {
                                                                                    part.title
                                                                                }
                                                                            </ActiveLink>
                                                                        )}
                                                                    </Draggable>
                                                                );
                                                            }
                                                        )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    {subButton.parts ? (
                                        <>
                                            <button
                                                className="w-sidebar-nav-button-parts"
                                                onClick={() => {
                                                    setLocalEnv({
                                                        ...localEnv,
                                                        modal: "create-part",
                                                        modalContent: {
                                                            type: "STORY",
                                                            story: subButton.object,
                                                        },
                                                    });
                                                }}
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <i className={"far fa-plus"} />
                                                New Part
                                            </button>
                                        </>
                                    ) : (
                                        <div className="w-sidebar-nav-button-parts">
                                            <i className={"far fa-circle"} />
                                            Loading...
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }
                    case "LINK":
                        return (
                            <ActiveLink
                                activeClass="active"
                                href={subButton.link}
                                className="w-sidebar-nav-button"
                                key={index}
                            >
                                <i className={"far fa-" + subButton.icon} />
                                {subButton.name}
                            </ActiveLink>
                        );
                }
            })}
        </>
    );
}
