import { useEffect, useState } from "react";
import { ThemeType } from "../types/ThemeTypes";
import { LoadEnv } from "./EnvUtils";

const commons = {
    accent: "#44c54d",
    accentActive: "#2aa733",
    accentFade: "rgba(42, 167, 50, 0.322)",
    font: "manrope",
    fontBold: "manrope-bold",
    warning: "yellow",
    warningActive: "#d6d600",
    danger: "red",
    dangerActive: "#d60000",
    backdropFilter: "blur(20px)",
};

export const Themes: ThemeType[] = [
    {
        name: "light",
        displayName: "Rann",
        scheme: {
            ...commons,
            primary: "white",
            primaryFont: "black",
            secondary: "#e9e9e9",
            secondaryActive: "#dbdbdb",
            primaryOpaque: "rgba(255, 255, 255, 0.5)",
            lightOpaque: "rgba(0, 0, 0, 0.05)",
            opaque: "rgba(0, 0, 0, 0.2)",
            opaqueActive: "rgba(0, 0, 0, 0.3)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(255, 255, 255, 0.950)",
        },
        type: "light",
    },
    {
        name: "dark",
        displayName: "Siyahi",
        scheme: {
            ...commons,
            primary: "#080808",
            primaryFont: "#eeeeee",
            secondary: "#181818",
            secondaryActive: "#292929",
            primaryOpaque: "rgba(8, 8, 8, 0.5)",
            lightOpaque: "rgba(255, 255, 255, 0.05)",
            opaque: "rgba(255, 255, 255, 0.200)",
            opaqueActive: "rgba(255, 255, 255, 0.300)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(8, 8, 8, 0.95)",
        },
        type: "dark",
    },
    {
        name: "grey",
        displayName: "Surmayee",
        scheme: {
            ...commons,
            primary: "#2c2c2c",
            primaryFont: "#eeeeee",
            secondary: "#353535",
            secondaryActive: "#3f3f3f",
            primaryOpaque: "rgba(44, 44, 44, 0.5)",
            lightOpaque: "rgba(255, 255, 255, 0.05)",
            opaque: "rgba(255, 255, 255, 0.200)",
            opaqueActive: "rgba(255, 255, 255, 0.300)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(44, 44, 44, 0.95)",
        },
        type: "dark",
    },
    {
        name: "blue",
        displayName: "Aasmani",
        scheme: {
            ...commons,
            primary: "#00081a",
            primaryFont: "rgb(255, 255, 255)",
            secondary: "#001135",
            secondaryActive: "#00133d",
            primaryOpaque: "rgba(0, 8, 26, 0.5)",
            lightOpaque: "rgba(170, 186, 212, 0.05)",
            opaque: "rgba(255, 255, 255, 0.200)",
            opaqueActive: "rgba(255, 255, 255, 0.300)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(0, 8, 26, 0.95)",
        },
        type: "dark",
    },
    {
        name: "red",
        displayName: "Sehar", //Tea by sunset
        scheme: {
            ...commons,
            primary: "#250000",
            primaryFont: "rgb(255, 255, 255)",
            secondary: "#3a0000",
            secondaryActive: "#5f3d3d",
            primaryOpaque: "rgba(37, 0, 0, 0.5)",
            lightOpaque: "rgba(212, 170, 170, 0.05)",
            opaque: "rgba(255, 255, 255, 0.200)",
            opaqueActive: "rgba(255, 255, 255, 0.300)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(37, 0, 0, 0.95)",
        },
        type: "dark",
    },
    {
        name: "pink",
        displayName: "Gulshan",
        scheme: {
            ...commons,
            primary: "#fae2e3",
            primaryFont: "black",
            secondary: "#f7d3d6",
            secondaryActive: "#f5c5c8",
            primaryOpaque: "rgba(250, 226, 227, 0.5)",
            lightOpaque: "rgba(0, 0, 0, 0.05)",
            opaque: "rgba(0, 0, 0, 0.2)",
            opaqueActive: "rgba(0, 0, 0, 0.3)",
        },
        lowHardware: {
            backdropFilter: "none",
            primaryOpaque: "rgba(250, 226, 227, 0.95)",
        },
        type: "light",
    },
];

export const changeTheme = (themeName: ThemeType["name"]) => {
    const themeMain = Themes.filter((t) => t.name === themeName)[0];
    const theme = themeMain.scheme;
    for (const property in theme) {
        const val = theme[property as keyof ThemeType["scheme"]];
        document.documentElement.style.setProperty("--" + property, val);
    }
    if (LoadEnv().lowhardware) {
        const lowTheme = themeMain.lowHardware;
        for (const property in lowTheme) {
            const val = lowTheme[property as keyof ThemeType["lowHardware"]];
            document.documentElement.style.setProperty("--" + property, val);
        }
    }
};

export const useThemeDetector = () => {
    const getCurrentTheme = () =>
        window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
    const mqListener = (e: any) => {
        setIsDarkTheme(e.matches);
    };

    useEffect(() => {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMq.addListener(mqListener);
        return () => darkThemeMq.removeListener(mqListener);
    }, []);
    return isDarkTheme;
};
