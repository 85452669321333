import { useContext } from "react";
import { LocalEnvContext } from "../../../contexts/env";
import { Fonts } from "../../../utils/FontUtils";

export default function FontContainer() {
    const [localEnv, setLocalEnv] = useContext(LocalEnvContext);

    return (
        <>
            <div className="w-modal-panel-title">Font</div>
            {Fonts.map((font, i) => {
                return (
                    <label
                        key={i}
                        className={
                            "w-modal-theme-box " +
                            (font.fontCSS === localEnv.font?.family &&
                                "selected")
                        }
                    >
                        <div
                            className="w-modal-theme-box-span"
                            style={{ fontFamily: font.fontCSS }}
                        >
                            {font.fontName}
                        </div>
                        <input
                            type="radio"
                            name="theme"
                            value={font.fontCSS}
                            onChange={(e) => {
                                setLocalEnv({
                                    ...localEnv,
                                    font: {
                                        ...localEnv.font,
                                        family: e.target.value,
                                    },
                                });
                            }}
                        />
                    </label>
                );
            })}
            <br />
            <br />
            <div className="w-modal-panel-title">
                Font Size - {localEnv.font?.size || "15"}
            </div>
            <div className="w-modal-panel-slider-wrap">
                <input
                    type="range"
                    min="14"
                    max="25"
                    value={localEnv.font?.size || "15"}
                    onChange={(e) =>
                        setLocalEnv({
                            ...localEnv,
                            font: {
                                ...localEnv.font,
                                size: parseInt(e.target.value),
                            },
                        })
                    }
                    className="w-modal-panel-slider"
                />
            </div>
            <br />
            <br />
            <div className="w-modal-panel-title">
                Line height - {localEnv.font?.lineHeight || "17"}
            </div>
            <div className="w-modal-panel-slider-wrap">
                <input
                    type="range"
                    min="10"
                    max="40"
                    value={localEnv.font?.lineHeight || "17"}
                    onChange={(e) =>
                        setLocalEnv({
                            ...localEnv,
                            font: {
                                ...localEnv.font,
                                lineHeight: parseInt(e.target.value),
                            },
                        })
                    }
                    className="w-modal-panel-slider"
                />
            </div>
        </>
    );
}
