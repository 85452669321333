import { usePath, useQueryParams, useRoutes } from "raviger";
import React, { ReactNode, useEffect } from "react";
import AppContainer from "../components/AppContainer";
import Error404 from "../components/errors/Error404";
import { API } from "../utils/ApiUtils";
import RouterFallback from "./RouterFallback";

const Dashboard = React.lazy(() => import("../components/home/Dashboard"));
const Login = React.lazy(() => import("../components/user/Login"));
const CreateStory = React.lazy(
    () => import("../components/stories/CreateStory")
);
const EditorMain = React.lazy(() => import("../components/editor/EditorMain"));
const NotesPage = React.lazy(() => import("../components/notes/Notes"));

const fallback = <RouterFallback />;

function LazyLoad(props: { children: ReactNode }) {
    return (
        <React.Suspense fallback={fallback}>{props.children}</React.Suspense>
    );
}

export default function AppRouter() {
    const path = usePath();
    const [queryParams] = useQueryParams();
    const { notif = "" } = queryParams;

    useEffect(() => {
        if (notif && notif !== "") {
            API.notifications.read(notif);
        }
    }, [notif]);

    const homeRoute = (
        <LazyLoad>
            <Dashboard />
        </LazyLoad>
    );
    const routes = {
        "/": () => homeRoute,
        "/home": () => homeRoute,

        "/create": () => (
            <LazyLoad>
                <CreateStory />
            </LazyLoad>
        ),

        "/story/:storySlug": ({ storySlug }: { storySlug: string }) => (
            <LazyLoad>
                <CreateStory story_id={storySlug} />
            </LazyLoad>
        ),

        "/story/:storySlug/:partSlug": ({
            storySlug,
            partSlug,
        }: {
            storySlug: string;
            partSlug: string;
        }) => (
            <LazyLoad>
                <EditorMain storySlug={storySlug} partSlug={partSlug} />
            </LazyLoad>
        ),

        "/notes": () => (
            <LazyLoad>
                <NotesPage />
            </LazyLoad>
        ),

        "/login": () => (
            <LazyLoad>
                <Login />
            </LazyLoad>
        ),
    };
    const routeResult = useRoutes(routes) || <Error404 />;

    return path === "/login" ? (
        routeResult
    ) : (
        <AppContainer>{routeResult}</AppContainer>
    );
}
