import { Link } from "raviger";
import "../../css/errorpage.scss";

export default function Error404(){
    return (
        <div className="w-error-wrap">
            <div className="w-error-container">
                <img src="/media/error_hd.png" alt="Error" className="w-error-image"/>
                <h1>
                    404 : Page not found
                </h1>
                <p>
                    It appears the page you are looking for either does not exist, has been moved, 
                    taken down or torn apart (seriously, who does that?).
                    Maybe try rechecking the link?
                    <br />
                    <br />
                    <Link href="/" className="w-button">
                        Return to Writeroo
                    </Link>
                </p>
            </div>
            
        </div>
    )
}